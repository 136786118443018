import * as check from './formsValidation';
import { classes, jQueryObjects, selectors } from './global';

const forms = () => {

  const { showClass, hiddenClass } = classes,
        { btnSelector, loaderSelector, regularError, criticalError } = selectors,
        { document } = jQueryObjects;

  function ajaxSubmission(formHtml, completeCallback, validationFunc=null, grecaptchaAction='') {
    const form = $(formHtml),
          btn = form.find(btnSelector),
          loader = form.find(loaderSelector);

    if (typeof validationFunc !== 'function' || validationFunc(form)) {
      btn.hide();
      loader.addClass(showClass);
      function ajax_call() {
        $.ajax({
          type: form.attr('method'),
          url: form.attr('action'),
          data: formData,
          contentType: false,
          processData: false,
          dataType: 'json',
          complete: function (jqXHR) {
            completeCallback(jqXHR.responseJSON, form, loader, btn);
          }
        });
      }

      const formData = new FormData(form.get(0));

      if (grecaptchaAction.length > 0) {
        grecaptcha.ready(function() {
          grecaptcha.execute(
            window.AWARDS.rpkv3,
            {action: grecaptchaAction}
          ).then(function(token) {
            formData.set('captcha', token || '-');
            ajax_call();
          });
        });
      } else {
        ajax_call();
      }
    }
  }

  function onSubmitLoginForm() {
    document.on('submit', '.js-form-login', function(e) {
      e.preventDefault();

      const callback = function(resp, form, loader, btn){
        let critical = true;

        if (resp) {
          if (resp.result == 'valid') {
            const nextUrl = new URL(window.location).searchParams.get('next');
            critical = false;
            if (resp.url) {
              window.location.replace(resp.url);
            } else if (nextUrl) {
              window.location.replace(nextUrl);
            } else {
              window.location.reload();
            }
          } else if (resp.result == 'invalid') {
            critical = false;
            for (const name in resp.errors){
              const msgs = [];
              let target = null;
              resp.errors[name].forEach(error => {
                msgs.push(error.message);
              });

              if (name == '__all__'){
                target = form.find(regularError);
              } else {
                target = form.find(`input[name=${name}]`);
              }
              if (msgs.length > 0){
                check.showErrorMsg(target, msgs.join('<br>'));
              }
            }
            btn.show();
            loader.removeClass(showClass);
          }
        }

        if (critical){
          loader.removeClass(showClass);
          form.find(criticalError).removeClass(hiddenClass);
        }
      }
      ajaxSubmission(e.target, callback, check.checkLoginForm, 'login');

    });
  };

  function onSubmitFulfillForm() {
    document.on('submit', '.js-form-fulfill', function(e) {
      e.preventDefault();

      const callback = function(resp, form, loader, btn){
        let critical = true;

        if (resp) {
          if (resp.result == 'valid') {
            critical = false;
            let origText = btn.text();
            btn.prop('disabled', true).text('успешно сохранено').show();
            loader.removeClass(showClass);
            setTimeout(() => {
              btn.prop('disabled', false).text(origText);
            }, 3000);
          } else if (resp.result == 'redirect') {
            const nextUrl = new URL(window.location).searchParams.get('next');
            critical = false;
            if (nextUrl) {
              window.location.replace(nextUrl);
            } else {
              window.location.reload();
            }
          } else if (resp.result == 'invalid') {
            critical = false;
            for (const name in resp.errors){
              const msgs = [];
              let target = null;
              resp.errors[name].forEach(error => {
                msgs.push(error.message);
              });

              if (name == '__all__'){
                target = form.find(regularError);
              } else {
                target = form.find(`input[name=${name}]`);
              }
              if (msgs.length > 0){
                check.showErrorMsg(target, msgs.join('<br>'));
              }
            }
            btn.show();
            loader.removeClass(showClass);
          }
        }

        if (critical){
          loader.removeClass(showClass);
          form.find(criticalError).removeClass(hiddenClass);
        }
      }
      ajaxSubmission(e.target, callback, check.checkFulfillForm, 'fulfill');

    });
  };

  function onSubmitSuggestForm() {
    document.on('submit', '.js-form-suggest', function(e) {
      e.preventDefault();

      const callback = function(resp, form, loader, btn){
        let critical = true;

        if (resp) {
          if (resp.result == 'valid') {
            critical = false;
            window.location.reload();
          } else if (resp.result == 'invalid') {
            critical = false;
            for (const name in resp.errors){
              const msgs = [];
              let target = null;
              resp.errors[name].forEach(error => {
                msgs.push(error.message);
              });

              if (name == '__all__'){
                target = form.find(regularError);
              } else {
                target = form.find(`[name=${name}]`);
              }
              if (msgs.length > 0){
                check.showErrorMsg(target, msgs.join('<br>'));
              }
            }
            btn.show();
            loader.removeClass(showClass);
          }
        }

        if (critical){
          loader.removeClass(showClass);
          form.find(criticalError).removeClass(hiddenClass);
        }
      }
      
      ajaxSubmission(e.target, callback, check.checkSuggestForm, 'suggest');

    });
  };

  onSubmitLoginForm();
  onSubmitFulfillForm();
  onSubmitSuggestForm();
};

export default forms;
