import { classes, selectors, jQueryObjects } from './global';

const vote = () => {

  function animationProgressBar() {
    $('.result__bar-progress').each(function(i, item) {
      const _this = $(item),
        width = '' + _this.data('progress') + '%',
        displayVotes = _this.closest('.result').find('.js-display-votes');

      _this
        .css('width', 0)
        .stop()
        .animate({
          width: width,
        }, 1700);

      animationPercent(displayVotes, 1400);
    });
  };

  function animationPercent(elem, duration) {
      let start = 0,
          current = start,
          end = parseFloat(elem.text()),
          range = end - start,
          increment = end > start ? 1 : 0,
          step = Math.abs(Math.floor(duration / range));
      const timer = setInterval(() => {
        current += increment;
        elem.text(current);
        if (current === end) {
          clearInterval(timer);
        }
      }, step);
  };

  function onClickBtnVote() {
    const { loaderSelector } = selectors,
          { document } = jQueryObjects,
          btnVoteSelector = '.js-btn-vote:not(.disable)';

    document.on('click', btnVoteSelector, function() {
      const _this = $(this),
            loader = _this.parent().find(loaderSelector),
            { showClass } = classes;

      $(btnVoteSelector).addClass('disable');
      _this.hide();
      loader.addClass(showClass);

      $.ajax({
        type: 'POST',
        url: _this.data('url'),
        dataType: 'json',
        complete: function(jqXHR){

          const resp = jqXHR.responseJSON;
          if (resp && resp.result == 'success'){
            _this.show();
            if (_this.closest('.popup').length > 0){
              _this.find('span').text('ваш выбор');
            }
            loader.removeClass(showClass);
            resp.suggested.forEach(function(el){
              const _el = $('#part-' + el.pk);
              _el.find('.js-display-votes').text(el.votes_percent);
              _el.find('.js-progress-votes')
                .data('progress', el.votes_percent)
                .attr('data-progress', el.votes_percent);

              const _elVoteBtn = _el.find('.js-btn-vote');
              if (_elVoteBtn.data('url') == _this.data('url')){
                _elVoteBtn.find('span').text('ваш выбор');
              }
            });
            animationProgressBar();
          } else if (resp && resp.result == 'error'){
            window.location.reload();
          } else {
            alert('Что-то пошло не так... Попробуйте обновить страницу и повторить попытку');
          }
        }
      });

    });
  };

  animationProgressBar();
  onClickBtnVote();
};

export default vote;
