import { classes } from './global';

function scrollTo(trigger, selector) {
  $(trigger).on('click', function() {
    $('html, body').animate({
      scrollTop: $(selector).offset().top
    }, 'slow');
  });
};

function showElemOnScroll(selector, offsetTop) {
  const { showClass } = classes;
  
  function showElem() {
    let scrollTop = $(window).scrollTop();

    if (scrollTop > offsetTop) {
      $(selector).addClass(showClass);
    } else {
      $(selector).removeClass(showClass);
    }
  };

  showElem();
  $(window).on('scroll', showElem);
};

function _debounce(func, time) {

  let timeout;
  
  return function() {

    if (timeout) {
      return false;
    }

    func.apply(this, arguments);

    timeout = true;

    setTimeout(() => {
      timeout = false
    }, time);
  };
};

function transliterate(word) {
  const keys = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
    'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
    'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
    'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
    'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
    'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya',
    'й': 'i', 'ъ': '', 'ь': ''
  }

  return word
          .toLowerCase()
          .split('')
          .map((letter) => {
              return typeof keys[letter] === 'undefined' ? letter : keys[letter]
          }).join('');
};

export {
  scrollTo,
  showElemOnScroll,
  _debounce,
  transliterate
};
