import { 
  classes,
  selectors,
  jQueryObjects
} from "./global";
import { transliterate } from './modules';

const popups = () => {
  const { bodyClass, showClass, inActiveClass } = classes,
        body = $(bodyClass),
        { menuSelector, btnCrossSelector } = selectors;

  function switchSuggestPopupNomination(btn, popup) {
    popup.find('.form__item-nomination span').text(btn.parent().find('.nomination__name').text());
    popup.find('.js-form-suggest').attr('action', btn.data('action'));
  };

  function switchNomineePopupData(btn, popup) {
    window.slideIndex = btn.data('index');
    popup.find('.about-nominee__title').text(btn.data('title'));
    popup.find('.about-nominee__subtitle').html(btn.data('subtitle'));
    popup.find('.about-nominee__text').html(btn.data('desc'));
    popup
      .find('.about-nominee__link')
      .attr('href', btn.data('url'));
      
    if (btn.data('url')){
      popup.find('.about-nominee__link').show();
    }

    popup.find('.about-nominee__img img')
      .attr({
        'src': btn.data('image_1'),
        'alt': btn.data('title')
      });

    if (btn.data('sponsor-title') || btn.data('sponsor-logo')){
      popup.find('.partner').show();
      popup.find('.partner__label').show();
    }
    popup.find('.partner .partner__label img')
      .attr({
        'src': btn.data('sponsor-logo'),
        'alt': btn.data('sponsor-title')
      });
    if (btn.data('sponsor-desc') || btn.data('sponsor-logo')){
      popup.find('.partner').show();
      popup.find('.partner_text').show();
    }
    popup.find('.partner .text').html(btn.data('sponsor-desc'));
    popup
      .find('.partner .partner__more')
      .attr('href', btn.data('sponsor-url'));

    if (btn.data('route-info')){
      popup.find('.route').show();
    }
    popup.find('.route__info').html(btn.data('route-info'));

    popup.find('.js-info-vote-btn-wrapper').prepend(
      btn.closest('.nominees__vote').find('.js-btn-to-info-popup').clone()
    );

    if (btn.data('place')) {
      popup.find('.about-nominee__place').show();
      popup.find('.place__num').text(btn.data('place'));
      popup.find('.place__title').text(btn.data('nomination'));
    }
  };

  function clearNomineePopupData(popup){
    popup.find('.about-nominee__title').text('');
    popup.find('.about-nominee__subtitle').html('');
    popup.find('.about-nominee__text').html('');
    popup.find('.about-nominee__link').removeAttr('href');
    popup.find('.about-nominee__link').hide();
    popup.find('.about-nominee__img img').removeAttr('src alt');

    popup.find('.about-nominee__place').hide();
    popup.find('.place__num').text('');
    popup.find('.place__title').text('');

    popup.find('.partner').hide();
    popup.find('.partner .partner__label img').attr({'src': '', 'alt': ''});
    popup.find('.partner .text').html('');
    popup.find('.partner .partner__more').removeAttr('href');

    popup.find('.route').hide();
    popup.find('.route__info').html('');

    popup.find('.js-info-vote-btn-wrapper .js-btn-to-info-popup').remove();
  };

  function switchSocialAuthPopupUrl(btn, popup){
    popup.find('.js-link-social-auth').each(function(i, item){
      const _item = $(item);
      _item.attr('href', 
      _item.data('base-url') +
        (btn.data('next-url') || _item.data('default-next-url'))
      );
    });
  };

  function showPopup(popup){
    popup.fadeIn(function() {
      if($(menuSelector).hasClass(showClass)) {
        $(`${menuSelector} ${btnCrossSelector}`).click();
      }
    }).css('display', 'flex');
    body.addClass(inActiveClass);
  };

  function bindPopup(trigger, popupSelector) {
    const { document } = jQueryObjects,
          popup = $(popupSelector),
          btnCloseSelector = `${popupSelector} ${btnCrossSelector}`;

    function bindShowHandler() {
      document.on('click', trigger, function(e) {
        e.preventDefault();
        const _this = $(this);
        if (_this.data('action')){
          switchSuggestPopupNomination(_this, popup);
        } else if (_this.hasClass('js-btn-info')){
          switchNomineePopupData(_this, popup);
          const urlName = transliterate('' + _this.data('title')).replace(/ /g, '-') + '=' + _this.data('id');
          window.history.replaceState({}, null, '#' + urlName);
        } else if (_this.hasClass('js-btn-social-auth')) {
          switchSocialAuthPopupUrl(_this, popup);
        }
        showPopup(popup);
      });
    };

    function bindHideHandler() {
      document.on('click', btnCloseSelector, function() {
        popup.fadeOut(300, function(){
          body.removeClass(inActiveClass);
          if (popup.hasClass('popup-nominee')){
            clearNomineePopupData(popup);
            window.history.replaceState({}, null, window.location.pathname);
          }
        });
      });
    };

    if (trigger.length > 0){
      bindShowHandler();
    }
    bindHideHandler();
  };

  bindPopup('.js-btn-about', '.popup-about');
  bindPopup('.js-btn-login', '.popup-auth');
  bindPopup('.js-btn-social-auth', '.popup-social-auth');
  bindPopup('', '.popup-registration');
  // bindPopup('.js-btn-add', '.popup-add-nominee');
  bindPopup('.js-btn-user-suggest', '.popup-user-suggest-nominee');
  bindPopup('', '.popup-success');
  bindPopup('.js-btn-info', '.popup-nominee');

  let popupJObj = null;
  switch(window.AWARDS.openPopup){
    case 'login':
      showPopup($('.popup-auth'));
      break;
    case 'fulfill':
      popupJObj = $('.popup-registration');
      popupJObj.find('.js-mode-fulfill').show();
      popupJObj.find('.js-mode-recheck').remove();
      showPopup(popupJObj);
      break;
    case 'recheck':
      popupJObj = $('.popup-registration');
      popupJObj.find('.js-mode-recheck').show();
      popupJObj.find('.js-mode-fulfill').remove();
      showPopup(popupJObj);
      break;
    case 'success':
      showPopup($('.popup-success'));
      break;
    // default:
    //   if (window.AWARDS.openPopup.indexOf('part-') === 0){
    //     $('#' + window.AWARDS.openPopup).find('.js-btn-info').trigger('click');
    //   }
  }
};

export default popups;
