window.slideIndex;

const classes = {
  activeClass: 'active',
  inActiveClass: 'inactive',
  showClass: 'show',
  hideClass: 'hide',
  errorClass: 'error',
  bodyClass: 'body',
  hiddenClass: 'hidden',
  formItemClass: 'form__item',
};

const selectors = {
  btnSelector: 'button.btn',
  btnCrossSelector: '.js-btn-close',
  menuSelector: '.menu-mobile',
  imgListSelector: '.upload-img__list',
  loaderSelector: '.loader',
  regularError: '.js-regular-error',
  criticalError: '.js-critical-error',
};

const jQueryObjects = {
  document: $(document),
};

const Cookies = require('js-cookie');

const csrftoken = Cookies.get('csrftoken');

export { 
  classes,
  selectors,
  jQueryObjects,
  csrftoken
};
