
const header = () => {
  const header = $('.header--gold'),
        voteComplete = $('.vote-complete');

  function setMarginTop() {
    const offsetTop = voteComplete.outerHeight();
    header.css('margin-top', offsetTop);
  };

  if(header.length) {
    setMarginTop();
    $(window).on('resize', setMarginTop);
  }
};

export default header;
