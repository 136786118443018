import { classes } from './global';
import { scrollTo, showElemOnScroll } from './modules';

const btnScrollTop = () => {
  const btn = '.js-btn-go-top',
        offsetTop = $(window).height(),
        { bodyClass } = classes;

  scrollTo(btn, bodyClass);
  showElemOnScroll(btn, offsetTop);
};

export default btnScrollTop;
