import { csrftoken } from './global';

const init = () => {
  $.ajaxSetup({
    beforeSend: function(xhr, settings) {
      // these HTTP methods do not require CSRF protection
      if (!(/^(GET|HEAD|OPTIONS|TRACE)$/.test(settings.type))
        && !this.crossDomain
      ){
        xhr.setRequestHeader("X-CSRFToken", csrftoken);
      }
    }
  });
}

export default init;
