import { classes, selectors } from './global';

const { errorClass, formItemClass } = classes,
      { regularError } = selectors,
      regExpMail = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ); //,
      // regExpUrl = new RegExp(
      //   /^(?:(?:http|https):\/\/)?(?:[a-z¡-￿0-9](?:[a-z¡-￿0-9-]{0,61}[a-z¡-￿0-9])?(?:\.(?!-)[a-z¡-￿0-9-]{1,63}(?<!-))*\.(?!-)(?:[a-z¡-￿-]{2,63}|xn--[a-z0-9]{1,59})(?<!-)\.?)(?::\d{2,5})?(?:[/?#][^\s]*)?$/,
      //   'i'
      // );
      // regExpNumber = new RegExp(/^[0-9]+$/)

const errorMessage = {
  empty: 'Обязательное поле',
  userLogin: 'Логин не найден',
  email: 'Некорректный email',
  url: 'Некорректная ссылка',
  phone: 'Некорректный номер',
  userEmail: 'Email не найден',
  userPassword: 'Неверный пароль ',
  captcha: 'Подтвердите, что вы не робот',
};

function showErrorMsg(target, text) {
  const tmp = `<span>${text}</span>`;
  if (!target.hasClass(formItemClass)){
    target = target.closest(`.${formItemClass}`);
  }
  target.addClass(errorClass);
  target.find('span:not(.required)').remove();
  target.append(tmp);
};

function hideErrorMsg(target) {
  if (!target.hasClass(formItemClass)){
    target = target.closest(`.${formItemClass}`);
  }
  target.removeClass(errorClass);
  target.find('span:not(.required)').remove();
};

function checkLoginForm(form) {
  const login = form.find('input[name="username"]'),
        loginValue = login.val().trim(),
        pass = form.find('input[name="password"]'),
        passValue = pass.val().trim();

  let loginError = true,
    passError = true;

  if (loginValue === '') {
    showErrorMsg(login, errorMessage.empty);
  } else if(!regExpMail.test(loginValue)) {
    showErrorMsg(login, errorMessage.email);
  } else {
    hideErrorMsg(login);
    loginError = false;
  }

  if (passValue === '') {
    showErrorMsg(pass, errorMessage.empty);
  } else {
    hideErrorMsg(pass);
    passError = false;
  }

  hideErrorMsg(form.find(regularError));

  if (loginError || passError) {
    return false;
  } else {
    return true;
  }
};

function checkFulfillForm(form) {
  const lastName = form.find('input[name="last_name"]'),
        firstName = form.find('input[name="first_name"]'),
        middleName = form.find('input[name="middle_name"]'),
        phone = form.find('input[name="phone"]'),
        alterEmail = form.find('input[name="alter_email"]'),
        lastNameValue = lastName.val().trim(),
        firstNameValue = firstName.val().trim(),
        middleNameValue = middleName.val().trim(),
        phoneValue = phone.val().trim(),
        alterEmailValue = alterEmail.val().trim();

  let lastNameError = true,
      firstNameError = true,
      middleNameError = true,
      phoneError = true,
      alterEmailError = true;

  if(lastNameValue === '') {
    showErrorMsg(lastName, errorMessage.empty);
  } else {
    hideErrorMsg(lastName);
    lastNameError = false;
  }

  if(firstNameValue === '') {
    showErrorMsg(firstName, errorMessage.empty);
  } else {
    hideErrorMsg(firstName);
    firstNameError = false;
  }

  if(middleNameValue === '') {
    showErrorMsg(middleName, errorMessage.empty);
  } else {
    hideErrorMsg(middleName);
    middleNameError = false;
  }
  
  if(phoneValue === '') {
    showErrorMsg(phone, errorMessage.empty);
  } else if(phoneValue.length < 18) {
    showErrorMsg(phone, errorMessage.phone);
  } else {
    hideErrorMsg(phone);
    phoneError = false;
  }

  if(alterEmailValue === '') {
    showErrorMsg(alterEmail, errorMessage.empty);
  } else if(!regExpMail.test(alterEmailValue)) {
    showErrorMsg(alterEmail, errorMessage.email);
  } else {
    hideErrorMsg(alterEmail);
    alterEmailError = false;
  }

  hideErrorMsg(form.find(regularError));

  if (lastNameError
    || firstNameError
    || middleNameError
    || phoneError
    || alterEmailError
  ) {
    return false;
  } else {
    return true;
  }
};

function checkSuggestForm(form) {
  const isUserSuggestForm = form.hasClass('js-form-user-suggest') ? true : false;

  const title = form.find('input[name="title"]'),
        titleValue = title.val().trim(),
        desc = form.find('textarea[name="desc"]'),
        descValue = desc.val().trim(),
        images = form.find('input[name^="image_"]'),
        userName = form.find('input[name="first_name"]'),
        userNameValue = userName.val().trim(),
        userRegion = form.find('input[name="region"]'),
        userRegionValue = userRegion.val().trim();

  let titleError = true,
      urlError = true,
      descError = true,
      userNameError = true,
      userRegionError = true,
      imageError = true,
      emailError = true;

  if(titleValue === '') {
    showErrorMsg(title, errorMessage.empty);
  } else {
    hideErrorMsg(title);
    titleError = false;
  }

  if(descValue === '') {
    showErrorMsg(desc, errorMessage.empty);
  } else {
    hideErrorMsg(desc);
    descError = false;
  }

  if (isUserSuggestForm) {
    if(userNameValue === '') {
      showErrorMsg(userName, errorMessage.empty);
    } else {
      hideErrorMsg(userName);
      userNameError = false;
    }

    if(userRegionValue === '') {
      showErrorMsg(userRegion, errorMessage.empty);
    } else {
      hideErrorMsg(userRegion);
      userRegionError = false;
    }
  }

  if(images.filter(function(){return !!$(this).val();}).length < 1) {
    if (isUserSuggestForm) {
      hideErrorMsg(images);
      imageError = false;
    } else {
      showErrorMsg(images, errorMessage.empty);
    }
  } else {
    hideErrorMsg(images);
    imageError = false;
  }

  hideErrorMsg(form.find(regularError));

  if (isUserSuggestForm) {
    const email = form.find('input[name="email"]'),
          emailValue = email.val().trim();
    if(emailValue === '') {
      showErrorMsg(email, errorMessage.empty);
    } else if(!regExpMail.test(emailValue)) {
      showErrorMsg(email, errorMessage.email);
    } else {
      hideErrorMsg(email);
    }

    emailError = false;
  } else {
    const url = form.find('input[name="url"]'),
          urlValue = url.val().trim();

    // TODO: исправить регулярку, чтобы не вылетало в Safari
    // if(urlValue.length > 0 && !regExpUrl.test(urlValue)) {
    //   showErrorMsg(url, errorMessage.url);
    // } else {
    //   hideErrorMsg(url);
    //   urlError = false;
    // }

    urlError = false;
  }

  if (isUserSuggestForm) {
    if (titleError || descError || emailError || userNameError || userRegionError) {
      return false;
    } else {
      return true;
    }
  } else {
    if (titleError || descError || imageError) {
      return false;
    } else {
      return true;
    }
  }
};

export {
  showErrorMsg,
  hideErrorMsg,
  checkLoginForm,
  checkFulfillForm,
  checkSuggestForm
};
