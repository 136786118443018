import { classes, selectors } from "./global";

const burgerMenu = () => {
  
  const btnMenu = $('.js-btn-menu'),
        { menuSelector } = selectors,
        menu = $(menuSelector),
        { showClass } = classes,
        btnClose = menu.find(selectors.btnCrossSelector);

  function showMenu() {
    btnMenu.on('click', function() {
      menu.addClass(showClass);
    });
  };

  function hideMenu() {
    btnClose.on('click', function() {
      menu.removeClass(showClass);
    });
  };

  showMenu();
  hideMenu();
};

export default burgerMenu;
