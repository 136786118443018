import { classes, jQueryObjects } from "./global";
import { _debounce, transliterate } from "./modules";

const sliderNominee = () => {
  const data = [],
        popup = $('.popup-nominee'),
        { document } = jQueryObjects;

  function createData() {
    $('.js-btn-info').each(function(i, item) {
      const _this = $(item);

      _this.attr('data-index', i);

      const obj = {
        title: _this.data('title'),
        subtitle: _this.data('subtitle'),
        desc: _this.data('desc'),
        url: _this.data('url'),
        image: _this.data('image_1'),
        sponsorLogo: _this.data('sponsor-logo'),
        sponsorTitle: _this.data('sponsor-title'),
        sponsorDesc: _this.data('sponsor-desc'),
        sponsorUrl: _this.data('sponsor-url'),
        routeInfo: _this.data('route-info'),
        placeNum: _this.data('place'),
        placeTitle: _this.data('nomination'),
        btnVote: _this.closest('.nominees__vote').find('.js-btn-to-info-popup'),
        dbId: _this.data('id')
      };

      data.push(obj);
    });
  };

  function showSlides() {
    if (window.slideIndex < 0) {
      window.slideIndex = data.length - 1;
    } else if (window.slideIndex > data.length -1) {
      window.slideIndex = 0;
    }

    const 
      {
        title,
        subtitle,
        desc,
        url,
        image,
        sponsorLogo,
        sponsorTitle,
        sponsorDesc,
        sponsorUrl,
        routeInfo,
        placeNum,
        placeTitle,
        btnVote,
        dbId
      } = data[window.slideIndex],
      nomineeImg = popup.find('.about-nominee__img'),
      nomineeLink = popup.find('.about-nominee__link'),
      partner = popup.find('.partner'),
      route = popup.find('.route'),
      nomineePlace = popup.find('.about-nominee__place'),
      btnVoteWrap = popup.find('.js-info-vote-btn-wrapper');

    const urlName = transliterate('' + title).replace(/ /g, '-') + '=' + dbId;
    window.history.replaceState({}, null, '#' + urlName);
      
    nomineeImg
      .hide()
      .fadeIn('slow');
    $('.about-nominee__item:last')
      .hide()
      .fadeIn('slow');

    popup
      .find('.about-nominee__title')
      .text(title);
    popup
      .find('.about-nominee__subtitle')
      .html(subtitle);
    popup
      .find('.about-nominee__text')
      .html(desc);
      
    if (url) {
      nomineeLink
        .attr('href', url)
        .show();
    } else {
      nomineeLink.hide();
    }

    nomineeImg
      .find('img')
      .attr('src', '')
      .attr({
        'src': image,
        'alt': title
      });

    if (sponsorTitle && sponsorLogo) {
      partner
        .find('.partner__label img')
        .attr({'src': '', 'alt': ''})
        .attr({
          'src': sponsorLogo,
          'alt': sponsorTitle
        });
      partner
        .find('.title')
        .text(sponsorTitle);
      partner
        .find('.text')
        .html(sponsorDesc);
      partner
        .find('.partner__more')
        .attr('href', sponsorUrl);
      partner.show();
    } else {
      partner.hide();
    }

    if (routeInfo) {
      route
        .find('.route__info')
        .html(routeInfo);
        route.show();
    } else {
      route.hide();
    }

    if (placeNum) {
      nomineePlace.show();
      nomineePlace
        .find('.place__num')
        .text(placeNum);
      nomineePlace
        .find('.place__title')
        .text(placeTitle);
    } else {
      nomineePlace.hide();
    }

    btnVoteWrap
      .find('button')
      .remove();

    btnVoteWrap.prepend(btnVote.clone());
  };

  function onClickBtnNext() {
    document.on('click', '.js-btn-next', _debounce(function() {
      ++window.slideIndex;
      showSlides();
    }, 400));
  };

  function onClickBtnPrev() {
    document.on('click', '.js-btn-prev', _debounce(function() {
      --window.slideIndex;
      showSlides();
    }, 400));
  };

  function init() {
    const nomineesItem = $('.nominees__item'),
          { hideClass, inActiveClass } = classes;

    if (nomineesItem.length > 1) {
      $('.btn-arrow').removeClass(hideClass);
      onClickBtnNext();
      onClickBtnPrev();
    }

    createData();

    let hashId = window.location.hash.split('=').pop();
    const btnInfo = $(`.js-btn-info[data-id="${hashId}"]`);

    if(hashId !== '' && btnInfo.length > 0) {
      btnInfo.click();
    } else if(hashId !== '') {
      window.history.replaceState({}, null, window.location.pathname);
    }

    nomineesItem.removeClass(inActiveClass);
  };
  
  if($('.page-nominees').length) {
    init();
  }
};

export default sliderNominee;
