'use strict';

import '.././scss/styles.scss';

import 'jquery-mask-plugin';
import 'social-likes';
import 'js-cookie';

import init from './modules/init';
import btnScrollTop from './modules/btnScrollTop';
import burgerMenu from './modules/burgerMenu';
import popups from './modules/popups';
import forms from './modules/forms';
import uploadPhoto from './modules/uploadPhoto';
import vote from './modules/vote';
import share from './modules/share';
import sliderNominee from './modules/slider';
import eventsGA from './modules/eventsGA';
import header from './modules/header';
import {voNav, voCountdown} from './modules/vo';

$(function () {
  init();
  btnScrollTop();
  burgerMenu();
  popups();
  forms();
  uploadPhoto();
  vote();
  share();
  eventsGA();
  header();
  sliderNominee();
  if (window.location.pathname === '/vladimir-region/') {
    voNav();
    // voCountdown();
  }
});
