const share = () => {

  function initSocialLikes() {
    $('.social-share').socialLikes({
      counters: false,
    });

    $('.js-share-tg').attr('href', `tg://msg_url?url=${window.location.href}&amp;text=${document.title}`);
  };

  initSocialLikes();
};

export default share;
