import { classes, selectors } from "./global";

const uploadPhoto = () => {

  const { hiddenClass } = classes,
        { imgListSelector } = selectors,
        imgList = $(imgListSelector);

  function showFirstEmptyInput(wrapper){
    wrapper.find('label').addClass(hiddenClass).each(function(){
      const _label = $(this);
      if (!_label.find('input').val()){
        _label.removeClass(hiddenClass);
        return false;
      }
    });
  }

  function onChangeInput() {
    $('.js-img-nominee').on('change', function() {
      const _this = $(this),
      _file = this.files[0];

      if (this.files && _file) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const tmp = 
            `<div class="upload-img__item js-img-preview" data-name="${_this.attr('name')}">
              <img class="cover" src="${e.target.result}" alt="">
            </div>`;

          imgList.append(tmp);
        }

        if (!_file.type.match('image/*')) {
          _this.val('');
          alert('Неверный формат фотографии');
        } else if (_file.size > 15728640) {
          _this.val('');
          alert('Большой размер фотографии');
        } else {
          _this.closest('label').addClass(hiddenClass);
          showFirstEmptyInput(_this.closest('.upload-img'));
          reader.readAsDataURL(_file);
        }
      }
    });
  };

  function onClickPreview() {
    $('.js-form-suggest').on('click', '.js-img-preview', function() {
      const _this = $(this);
      _this.closest('.upload-img').find(`input[name=${_this.data('name')}]`).val('');
      showFirstEmptyInput(_this.closest('.upload-img'));
      _this.remove();
    });
  };

  onChangeInput();
  onClickPreview();
};

export default uploadPhoto;
