function getCorrectEnding(number, one, two, five) {
    if (number > 10 && number < 20) return five;
    const lastDigit = number % 10;
    if (lastDigit === 1) return one;
    if (lastDigit >= 2 && lastDigit <= 4) return two;
    return five;
}

const voNav = () => {
    const menuItems = document.querySelectorAll('.vo__intro-menu-item');

    menuItems.forEach(item => {
        item.addEventListener('click', function() {
            const sectionId = this.getAttribute('data-section');
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        });
    });
}

const voCountdown = () => {
    const countdownDate = new Date('August 9, 2024 00:00:00 GMT+3').getTime();

    const now = new Date().getTime();
    const distance = countdownDate - now;

    if (distance < 0) {
        clearInterval(x);
        document.getElementById('countdown').innerHTML = "Праздник начался!";
        return;
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

    const days_wrapper = document.getElementById('days');
    const hours_wrapper = document.getElementById('hours');
    const minutes_wrapper = document.getElementById('minutes')

    days_wrapper.innerHTML = days;
    hours_wrapper.innerHTML = hours;
    minutes_wrapper.innerHTML = minutes;

    days_wrapper.nextSibling.nodeValue = ' ' + getCorrectEnding(days, 'день', 'дня', 'дней');
    hours_wrapper.nextSibling.nodeValue = ' ' + getCorrectEnding(hours, 'час', 'часа', 'часов');
    minutes_wrapper.nextSibling.nodeValue = ' ' + getCorrectEnding(minutes, 'минута', 'минуты', 'минут');
}

export {voNav, voCountdown};